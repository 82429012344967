import React, { useEffect, useState, useRef, memo, useMemo, useCallback } from "react";
import SideBar from "../components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { fetchAllMedaiData, uploadMedaiData } from "../actions/mediaActions";
import { fetchDeviceIdData } from "../actions/logActions";
import TablePagination from '@mui/material/TablePagination';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import noData from "../images/no-data.svg";
import { Image } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";

function MediaUploads(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userLogin = useSelector((state) => state.userLogin);
    const log = useSelector((state) => state.log);
    const media = useSelector((state) => state.media);

    // Modal States
    const [deviceIdFilter, setDeviceIdFilter] = useState("");
    const [typeFilter, setTypeFilter] = useState("");
    const [content, setContent] = useState("");
    const [recipient, setRecipient] = useState("all");
    const [deviceId, setDeviceId] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [filename, setFilename] = useState("");
    const [fileDescription, setFileDescription] = useState("");
    const [file, setFile] = useState(null);
    const { userInfo } = userLogin;
    const customerName = userInfo?.allorganization?.[0]?.customer_name || ""
    const modalRef = useRef(null);
    const deviceList = log.deviceIdData ? log.deviceIdData : []
    const deviceListUser = userInfo ? userInfo.devices : [];
    const allDevice = deviceListUser.length ? Array.isArray(deviceListUser) ? deviceListUser : [] : Array.isArray(deviceList) ? deviceList : []
    const [errors, setErrors] = useState({ filename: false, fileDescription: false, file: false, deviceId: false, })
    const mediaList = media?.allMediaData || [];
    const mediaData = useMemo(() => {
        return Array.isArray(mediaList?.media) ? mediaList?.media : [];
    }, [mediaList?.media]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const totalRecords = mediaList?.total_records || 0;
    const uploadMediaDataSuccess = media.uploadMedaiData ? media?.uploadMedaiData.status : '';
    const mediaUploadError = media.uploadError
    const uploadMediaLoading = media.uploadLoading
    const AllMediaLoading = media.loading
    const [showAlert, setShowAlert] = useState(false);
    const [showError, setShowError] = useState(false);
    const [ipAddress, setIpAddress] = useState("127.0.0.1");
    const [deviceError, setDeviceError] = useState(false);

    useEffect(() => {
        axios.get("https://checkip.amazonaws.com/")
            .then(response => {
                setIpAddress(response.data.trim());
            })
            .catch(error => {
                console.error("Error fetching IP address:", error);
            });
    }, []);

    useEffect(() => {
        dispatch(fetchDeviceIdData(customerName || ''));
    }, [dispatch]);

    useEffect(() => {
        const data = {
            customer_name: customerName || '',
            devices: deviceListUser.length ? deviceListUser : [],
            device_id: deviceIdFilter ? deviceIdFilter : '',
            type: typeFilter ? typeFilter : '',
            description: content ? content : '',
            page: page == 0 ? 1 : page + 1,
            page_size: rowsPerPage,
        };
        dispatch(fetchAllMedaiData(data));

    }, [dispatch, page, rowsPerPage, deviceIdFilter, typeFilter, content, uploadMediaDataSuccess])

    useEffect(() => {
        if (uploadMediaDataSuccess == "success") {
            closeModal();
            setShowAlert(true)
            setDeviceId('');
            setFileDescription('');
            setFilename('');
            setFile(null);
        }

        if (mediaUploadError) {
            setShowError(true)
        }


    }, [dispatch, uploadMediaDataSuccess, mediaUploadError])


    // Handle file selection
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    // Open and close modal
    const openModal = () => setShowModal(true);

    const closeModal = () => {
        setDeviceId('');
        setFileDescription('');
        setFilename('');
        setFile(null);
        setShowModal(false);
    }

    // Handle recipient selection
    const handleRecipientChange = (e) => {
        setRecipient(e.target.value);
    };

    // Handle device selection
    const handleDeviceChange = (e) => {
        setDeviceId(e.target.value);
    };

    const handleDeviceFilterChange = (e) => {
        setDeviceIdFilter(e.target.value);
    };


    const handleTypeFilterChange = (e) => {
        setTypeFilter(e.target.value);
    };

    const handleContentChange = (e) => {
        setContent(e.target.value);
    };

    const validateFields = () => {
        const newErrors = {
            filename: filename.trim() === "",
            fileDescription: fileDescription.trim() === "",
            file: !file,
            deviceId: recipient === "one" && deviceId.trim() === "",
        };
        setErrors(newErrors);
        return !Object.values(newErrors).includes(true);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };



    // Handle upload
    const handleUpload = () => {
        if (!validateFields()) {
            return;
        }

        if (allDevice.length === 0) {
            setDeviceError(true);
            return;
        }


        try {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = async () => {
                const base64String = reader.result.split(",")[1];

                const payload = {
                    devices: recipient == 'all' ? allDevice : [deviceId],
                    content: base64String,
                    file_name: filename,
                    type: file.type.split("/")[0],
                    extension: file.name.split(".").pop(),
                    description: fileDescription,
                    ip: ipAddress,
                    generate_guid: true,
                };

                console.log("payload", payload)

                dispatch(uploadMedaiData(payload))
            };
        } catch (error) {
            console.error("Upload Error:", error);
            alert("Failed to upload file.");
        }
    };


    const MediaPreview = ({ fileUrl, fileType }) => {
        if (!fileUrl) return <p>No media to preview</p>;

        if (fileType.startsWith("video")) {
            return <video controls height="90" src={fileUrl} />;
        } else if (fileType.startsWith("audio")) {
            return <audio controls src={fileUrl} />;
        } else {
            return <img height="90" src={fileUrl} alt="preview" />;
        }
    };


    const mediaRows = useMemo(() => {
        return mediaData.map((item) => (
            <tr key={item.id}>
                <td>{item.id}</td>
                <td>
                    <MediaPreview fileUrl={item.file} fileType={item.type} />
                </td>
                <td>{item.client_name}</td>
                <td>{item.type}</td>
                <td>{item.name}</td>
                <td>{item.description}</td>
            </tr>
        ));
    }, [mediaData]);

    return (
        <div className="App sidebar-screen">
            <SideBar />
            <main style={{ width: "100vw" }} className="supportMain">
                <div className="supportContainer">
                    {/* <div className="flex-container">
                        <div className="page-title-main mt-3">
                            <h2></h2>
                            <button
                                onClick={openModal}
                            >
                                Media Uploads
                            </button>
                        </div>
                    </div> */}
                    {/* Light Grey Divider Line */}
                    {/* <hr className="divider" /> */}

                    {/* Filters Section */}
                    <div className="filters mt-4">
                        <h5>Filters:</h5>
                        {/* Device ID Dropdown */}
                        <select
                            value={deviceIdFilter}
                            onChange={handleDeviceFilterChange}
                        >
                            <option value="">Device ID</option>
                            {allDevice.map((device, index) => (
                                <option key={index} value={device}>
                                    {device}
                                </option>
                            ))}
                        </select>

                        {/* File Type Dropdown */}
                        <select
                            value={typeFilter}
                            onChange={handleTypeFilterChange}
                        >
                            <option value="">Type</option>
                            <option value="image">Image</option>
                            <option value="audio">Audio</option>
                            <option value="video">Video</option>
                            <option value="screen">Screen</option>
                            <option value="takepic">Takepic</option>
                            <option value="application">Application</option>
                        </select>

                        {/* Name & Description Search Box */}
                        <input
                            type="text"
                            placeholder="Search by Name or Description"
                            value={content}
                            onChange={handleContentChange}
                        />
                        <button
                            onClick={openModal}
                        >
                            Media Uploads
                        </button>
                    </div>

                    {/* Light Grey Divider Line */}
                    <hr className="divider" />

                    {/* Table Section */}

                    {AllMediaLoading == true ? (
                        <Spinner className="custom-spinner" animation="border" style={{ width: "3rem", height: "3rem" }} />
                    ) : (
                        < div className="table-container">
                            <table                        >
                                {/* Table Headers */}
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Preview</th>
                                        <th>Device ID</th>
                                        <th>Type</th>
                                        <th>Name</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>

                                {/* Table Body */}
                                <tbody>
                                    {mediaData.total_records === 0 || media?.allMediaData.status == "no media" ? (
                                        <tr>
                                            <td colSpan="6" className="no-records">
                                                <div className='image-nodata'>
                                                    <Image height="100" src={noData} alt="noData" />
                                                </div>
                                                <h5 className="p-3"> No record Found....</h5>

                                            </td>
                                        </tr>
                                    ) : (
                                        mediaRows
                                    )}
                                </tbody>

                            </table>
                        </div>
                    )}

                    <Snackbar
                        open={showAlert}
                        autoHideDuration={3000}
                        onClose={() => setShowAlert(false)}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                        <Alert onClose={() => setShowAlert(false)} severity="success">
                            File uploaded sucessfully.
                        </Alert>
                    </Snackbar>

                    <Snackbar
                        open={showError}
                        autoHideDuration={3000}
                        onClose={() => setShowError(false)}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                        <Alert onClose={() => setShowAlert(false)} severity="error">
                            {mediaUploadError}
                        </Alert>
                    </Snackbar>


                    {/* Pagination Section */}
                    <div className="pagination">
                        <TablePagination
                            component="div"
                            count={totalRecords}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Items per page"
                        />
                    </div>
                </div>
            </main >
            {/* Modal for Upload Media */}
            {showModal && (
                <div className="modal-overlay">
                    {uploadMediaLoading && (
                        <div className="loader-overlay">
                            <Spinner className="custom-spinner" animation="border" style={{ width: "3rem", height: "3rem" }} />
                        </div>
                    )}
                    <div className="modal-container" ref={modalRef}>
                        <div className="modal-header">
                            <h3>Upload Media</h3>
                            <button className="close-button" onClick={closeModal}>
                                <RxCross2 />
                            </button>
                        </div>
                        <p className=" mt-2">Accepted file types: .pdf, .doc, .png, .mp4, .mp3, etc.</p>

                        <div className="modal-field">
                            <label className="label">Select Recipient:</label>
                            <label>
                                <input type="radio" value="all" checked={recipient === "all"} onChange={handleRecipientChange} />
                                All Devices
                            </label>
                            <label className="ms-3">
                                <input type="radio" value="one" checked={recipient === "one"} onChange={handleRecipientChange} />
                                One Device
                            </label>
                        </div>

                        {recipient === "one" && (
                            <div className="modal-field">
                                <label className="label">Select Device ID:</label>
                                <select value={deviceId} onChange={handleDeviceChange}
                                    className={`input-field ${recipient === "one" && !deviceId ? "error-border" : ""}`}>
                                    <option value="">Select Device</option>
                                    {allDevice.map((device, index) => (
                                        <option key={index} value={device}>
                                            {device}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}

                        <div className="modal-field">
                            <label className="label">File Name:</label>
                            <input
                                type="text"
                                value={filename}
                                onChange={(e) => setFilename(e.target.value)}
                                className={`ms-1 input-field ${errors.filename ? "error-border" : ""}`}
                            />
                        </div>

                        <div className="modal-field">
                            <label className="label">Description:</label>
                            <textarea
                                value={fileDescription}
                                onChange={(e) => setFileDescription(e.target.value)}
                                className={`ms-1 input-field ${errors.fileDescription ? "error-border" : ""}`}
                            />
                        </div>

                        <div className="modal-field">
                            <label className="label">Select File:</label>
                            <input type="file" onChange={handleFileChange} className="ms-1" />
                        </div>
                        {errors.file && !file && <div className="error-text">No file uploaded</div>}
                        {deviceError && <div className="error-text">Please add at least one device before uploading.</div>}

                        <button className="upload-button" onClick={handleUpload}>
                            Upload
                        </button>
                    </div>
                </div>
            )}
        </div >
    );
}

export default MediaUploads;


