// Home Screen

import { React, useEffect } from "react";
import SideBar from "../components/SideBar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function HomeScreen() {
  const navigate = useNavigate()
  // user state
  const userLogin = useSelector((state) => state.userLogin);
  var { userInfo } = userLogin; // destructured userInfo

  const user = userInfo; //get user from global state

  // runs once after the home is rendered
  useEffect(() => {
    if (!userInfo) {
      navigate("/"); // navigate to login
    }
  }, []);
  return (
    <div className="App sidebar-screen">
      <SideBar />
      <main style={{ width: "100vw" }} className="reportsMain">
        {user && user.features.length === 0 && (
          <div className="mt-5">
            <h3 style={{color:"grey", opacity:"0.6"}}>No features are currently enabled</h3>
          </div>
        )}
      </main>
    </div>
  );
}

export default HomeScreen;
      