/**
 * Action file for Announcement API fetch from backend
 */
import axios from 'axios';

export const FETCH_UPDATE_DATA_REQUEST = 'FETCH_UPDATE_DATA_REQUEST';
export const FETCH_UPDATE_DATA_SUCCESS = 'FETCH_UPDATE_DATA_SUCCESS';
export const FETCH_UPDATE_DATA_FAILURE = 'FETCH_UPDATE_DATA_FAILURE';

export const fetchUpdateData = () => {
  // axios
  //     .get('/media')
  //     .then((response) => {
  //       console.log('8588585', response)
  //     })
  //     .catch((error) => {
  //       console.log('741741', error)
  //     });
  return (dispatch) => {
    dispatch({ type: FETCH_UPDATE_DATA_REQUEST });

    axios
      .get('/v1/updates/GetUpdates/?format=json')
      .then((response) => {
        dispatch({
          type: FETCH_UPDATE_DATA_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_UPDATE_DATA_FAILURE,
          payload: error.message,
        });
      });
  };
};
