import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import IframeScreenComponent from "./iframecomponent";
import HomeScreen from "./HomeScreen";
import IframeScreen from "./IframeScreen";
import LinkScreen from "./LinksScreen";
import SupportScreen from "./SupportScreen";
import UpdateScreen from "./UpdateScreen";
import CustomerLog from "./CustomerLog";
import OutcomeLog from "./OutcomeLog";
import MediaUploads from "./MediaUploads";
import { revokePermissions } from './permissions';

function RenderScreen() {
  const location = useLocation()
  const [screenRender, setScreenRender] = useState("")
  // user state
  const userLogin = useSelector((state) => state.userLogin);
  var { userInfo } = userLogin; // destructured userInfo
  const user = userInfo; //get user from global state
  const msg = "Can't get the details at the moment. Please try again after some time."
  //this function is used to get the embedcode //docker-compose up --build -d
  const GetScreenToRender = () => {

    try {
      user.features.forEach((entry) => {
        if (entry.feature.config !== null) {
          if (location.pathname == entry.config.redirectTo) {
            const screen = ScreenToBeRender(entry)
            setScreenRender(screen)
          }
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Run only once on component mount
  useEffect(() => {
    GetScreenToRender();
  }, [location.pathname]);

  const ScreenToBeRender = (entry) => {
    const type = entry.config.type
    if (type !== "iframe") {
      revokePermissions();
    }
    var name = ""
    if (entry.feature.name !== "") {
      name = entry.feature.name
    }
    // Render different screens based on the type
    let screenToRender;
    switch (type) {
      case "iframe":
        const embedCode = entry.config.embedCode
        screenToRender = <IframeScreenComponent name={name} embedCode={embedCode} notFoundMsg={msg} />
        break;
      case "link":
        screenToRender = <LinkScreen entry={entry} />;
        break;
      case "support":
        screenToRender = <SupportScreen entry={entry} />;
        break;
      case "update":
        screenToRender = <UpdateScreen entry={entry} />;
        break;
      case "upload":
        screenToRender = <MediaUploads entry={entry} />;
        break;
      case "customerlogs":
        screenToRender = <CustomerLog entry={entry} />;
        break;
      case "outcomelogs":
        screenToRender = <OutcomeLog entry={entry} />;
        break;
      default:
        screenToRender = <div>No page found</div>
        break;
    }
    return screenToRender
  }


  return (
    <>
      {screenRender}
    </>
  )
}

export default RenderScreen;