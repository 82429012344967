import axios from 'axios';

export const FETCH_TYPE_DATA_REQUEST = 'FETCH_TYPE_DATA_REQUEST';
export const FETCH_TYPE_DATA_SUCCESS = 'FETCH_TYPE_DATA_SUCCESS';
export const FETCH_TYPE_DATA_FAILURE = 'FETCH_TYPE_DATA_FAILURE';
export const FETCH_OUTCOME_LOG_DATA_REQUEST = 'FETCH_OUTCOME_LOG_DATA_REQUEST';
export const FETCH_OUTCOME_LOG_DATA_SUCCESS = 'FETCH_OUTCOME_LOG_DATA_SUCCESS';
export const FETCH_OUTCOME_LOG_DATA_FAILURE = 'FETCH_OUTCOME_LOG_DATA_FAILURE';
export const FETCH_CUSTOMER_LOG_DATA_REQUEST = 'FETCH_CUSTOMER_LOG_DATA_REQUEST';
export const FETCH_CUSTOMER_LOG_DATA_SUCCESS = 'FETCH_CUSTOMER_LOG_DATA_SUCCESS';
export const FETCH_CUSTOMER_LOG_DATA_FAILURE = 'FETCH_CUSTOMER_LOG_DATA_FAILURE';
export const FETCH_DEVICE_ID_DATA_REQUEST = 'FETCH_DEVICE_ID_DATA_REQUEST';
export const FETCH_DEVICE_ID_DATA_SUCCESS = 'FETCH_DEVICE_ID_DATA_SUCCESS';
export const FETCH_DEVICE_ID_DATA_FAILURE = 'FETCH_DEVICE_ID_DATA_FAILURE';



const django_base_url = process.env.REACT_APP_DJANGO_BASE_URL;
const api_key = process.env.REACT_APP_CAREDOTCOACHAUTH

export const fetchTypeData = () => async (dispatch) => {
    dispatch({ type: FETCH_TYPE_DATA_REQUEST });

    try {
        const response = await axios.get(`${django_base_url}/log/process/sub-types`, {
            headers: { 'caredotcoachauth': api_key },
            maxBodyLength: Infinity,
        });

        dispatch({
            type: FETCH_TYPE_DATA_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_TYPE_DATA_SUCCESS,
            payload: error.message || 'Something went wrong',
        });
        console.error('Error fetching type data:', error);
    }
};

export const fetchOutcomelogData = (data) => async (dispatch) => {
    dispatch({ type: FETCH_OUTCOME_LOG_DATA_REQUEST });

    try {
        const response = await axios.post(
            `${django_base_url}/legacy/get_outcome_logs/`, 
            data, 
            {
                headers: { 'caredotcoachauth': api_key }
            }
        );

        dispatch({
            type: FETCH_OUTCOME_LOG_DATA_SUCCESS,
            payload: response.data || [],
        });

    } catch (error) {
        dispatch({
            type: FETCH_OUTCOME_LOG_DATA_FAILURE,
            payload: error.message || 'Something went wrong',
        });
        console.error('Error posting Outcome data:', error);
    }
};

export const fetchCustomerlogData = (data) => async (dispatch) => {
    dispatch({ type: FETCH_CUSTOMER_LOG_DATA_REQUEST });

    try {
        const response = await axios.post(
            `${django_base_url}/legacy/get_customer_logs/`, 
            data, 
            {
                headers: { 'caredotcoachauth': api_key }
            }
        );

        dispatch({
            type: FETCH_CUSTOMER_LOG_DATA_SUCCESS,
            payload: response.data || [],
        });

    } catch (error) {
        dispatch({
            type: FETCH_CUSTOMER_LOG_DATA_FAILURE,
            payload: error.message || 'Something went wrong',
        });
        console.error('Error posting Customer data:', error);
    }
};

export const fetchDeviceIdData = (customer) => async (dispatch) => {
    dispatch({ type: FETCH_DEVICE_ID_DATA_REQUEST });

    try {
        const response = await axios.get(`${django_base_url}/legacy/client_for_portal/`, {
            params: { customer },
            headers: { 'caredotcoachauth': api_key },
            maxBodyLength: Infinity,
        });

        dispatch({
            type: FETCH_DEVICE_ID_DATA_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        dispatch({
            type: FETCH_DEVICE_ID_DATA_FAILURE,
            payload: error.message || 'Something went wrong',
        });
        console.error('Error fetching device data:', error);
    }
};

