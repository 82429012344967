/**
 * Reducer file for Update API Endpoint
 */
import {
  FETCH_TYPE_DATA_REQUEST, FETCH_TYPE_DATA_SUCCESS, FETCH_TYPE_DATA_FAILURE,
  FETCH_OUTCOME_LOG_DATA_REQUEST, FETCH_OUTCOME_LOG_DATA_SUCCESS, FETCH_OUTCOME_LOG_DATA_FAILURE,
  FETCH_CUSTOMER_LOG_DATA_REQUEST, FETCH_CUSTOMER_LOG_DATA_SUCCESS, FETCH_CUSTOMER_LOG_DATA_FAILURE,
  FETCH_DEVICE_ID_DATA_REQUEST, FETCH_DEVICE_ID_DATA_SUCCESS, FETCH_DEVICE_ID_DATA_FAILURE
} from '../actions/logActions';

const initialState = {
  loading: false,
  typeData: {},
  error: null,
  outcomeLogLoading: false,
  outcomeLogData: {},
  outcomeLogerror: null,
  customerLogLoading: false,
  customerLogData: {},
  customerLogerror: null,
  deviceIdLoading: false,
  deviceIdData: {},
  deviceIdError: null,
};

export const logReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TYPE_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        typeData: {}
      };
    case FETCH_TYPE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        typeData: action.payload,
        error: null,
      };
    case FETCH_TYPE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        typeData: {}
      };
    case FETCH_OUTCOME_LOG_DATA_REQUEST:
      return {
        ...state,
        outcomeLogLoading: true,
        outcomeLogerror: null,
        outcomeLogData: {}
      };
    case FETCH_OUTCOME_LOG_DATA_SUCCESS:
      return {
        ...state,
        outcomeLogLoading: false,
        outcomeLogData: action.payload,
        outcomeLogerror: null,
      };
    case FETCH_OUTCOME_LOG_DATA_FAILURE:
      return {
        ...state,
        outcomeLogLoading: false,
        outcomeLogerror: action.payload,
        outcomeLogData: {}
      }
    case FETCH_CUSTOMER_LOG_DATA_REQUEST:
      return {
        ...state,
        customerLogLoading: true,
        customerLogerror: null,
        customerLogData: {}
      };
    case FETCH_CUSTOMER_LOG_DATA_SUCCESS:
      return {
        ...state,
        customerLogLoading: false,
        customerLogData: action.payload,
        customerLogerror: null,
      };
    case FETCH_CUSTOMER_LOG_DATA_FAILURE:
      return {
        ...state,
        customerLogLoading: false,
        customerLogerror: action.payload,
        customerLogData: {}
      };
    case FETCH_DEVICE_ID_DATA_REQUEST:
      return {
        ...state,
        deviceIdLoading: true,
        deviceIdError: null,
        deviceIdData: {}
      };
    case FETCH_DEVICE_ID_DATA_SUCCESS:
      return {
        ...state,
        deviceIdLoading: false,
        deviceIdData: action.payload,
        deviceIdError: null,
      };
    case FETCH_DEVICE_ID_DATA_FAILURE:
      return {
        ...state,
        deviceIdLoading: false,
        deviceIdError: action.payload,
        deviceIdData: {}
      };
    default:
      return state;
  }
};
