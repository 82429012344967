// Login Screen

import { React, useEffect, useState } from "react";
import { login } from "../helpers/authService";
import MicrosoftLoginButton from "../components/MicrosoftLoginButton";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { microsoftLogin, emailLogin, emailLoginOrg } from "../actions/accountActions";
import Loading from "../components/Loading";
import { FluentCard } from "../components/FluentComponents";
import cclogo from "../images/Care_Coach_Logo_inline_400x267.jpg";
import { Button, Image } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import msal from "../images/msal.png";
import mailline from "../images/mailline.png";
import arrowleftline from "../images/arrowleftline.png";
import {
  MICROSOFT_LOGIN_FAIL,
  EMAIL_LOGIN_SUCCESS,
} from "../constants/accountConstants";

function LoginScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // home screen route
  const home = "/updates";

  // get logged in user from redux store
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, success, userInfo, subOrgList } = userLogin;
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false)
  const [urlData, setUrlData] = useState({
    queryParams: {},
  });

  useEffect(() => {
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Parse query parameters
    const searchParams = new URLSearchParams(currentUrl.search);
    const queryParams = {};
    for (const [key, value] of searchParams.entries()) {
      queryParams[key] = value;
    }

    // Decode user_data if it exists
    if (queryParams.user_data) {
      try {
        queryParams.user_data = JSON.parse(decodeURIComponent(queryParams.user_data));
        if (!isWithinLast30Minutes(queryParams.time)) {
          // alert("Your link is expired")
          dispatch({
            type: MICROSOFT_LOGIN_FAIL,
            payload: "Your link is expired!",
          });
        } else {
          dispatch(emailLoginOrg(queryParams));
        }
      } catch (error) {
        console.error("Error decoding user data:", error);
      }
    }

    // Set extracted data to state
    setUrlData({ queryParams });
  }, []);

  const isWithinLast30Minutes = (time) => {
    const thirtyMinutesInMs = 30 * 60 * 1000;
    const currentTime = Date.now();
    const givenTime = time * 1000; // Convert to milliseconds

    return currentTime - givenTime <= thirtyMinutesInMs;
  };

  useEffect(() => {
    if (userInfo) {
      navigate(home);
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    if (subOrgList) {
      navigate('/select_org')
    }
  }, [subOrgList])

  // Login to Microsoft, get ID token and dispatch to API
  const handleLogin = async () => {
    setIsEmailLogin(false);
    const account = await login();
    if (account) {
      dispatch(microsoftLogin(account.idToken));
    }
  };

  const handleLoginEmail = async () => {
    if(email && emailRegex.test(email)){
      await dispatch(emailLogin(email));
      setEmail('')
    }else{
      setEmailError(true)
    }
  };

  const handleEmailLogin = async () => {
    setIsEmailLogin(true);
  }

  const backfunc = () => {
    setIsEmailLogin(false);
    dispatch({
      type: EMAIL_LOGIN_SUCCESS,
      payload: "",
    });
  }

  return (
    <div
      className="text-center justify-center .item-center logincontainer"
      style={{ backgroundColor: loading ? "white" : "rgb(77, 77, 77)", overflow: 'auto' }}
    >
      {loading ? (
        <Loading />
      ) : (
        isEmailLogin ?
          <Row>
            <Col
              style={{
                marginTop: "18vh",
              }}
            >
              <FluentCard className="fluent-card">
                <div className="containerLogo">
                  <img
                    src={cclogo}
                    alt="care-coach logo"
                    style={{ width: "250px" }}
                  />
                  <div className="text-overlay">
                    <h4>Portal</h4>
                  </div>
                </div>
                {!success ?
                  <div style={{marginLeft: '23%', minWidth: '100%'}}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Email address"
                      className="mb-3"
                      style={{
                        maxWidth: '248px',
                        border: emailError ? '1px solid red': '1px solid #dee2e6',
                        borderRadius:'0.375rem'
                      }}
                    >
                      <Form.Control type="email" placeholder="name@example.com"
                        onChange={(e) => {setEmail(e.target.value); setEmailError(false)}}
                        value={email} />
                    </FloatingLabel>
                    <Button className="button microsoft microsoftButton" onClick={handleLoginEmail}>
                      <Image src={mailline} className="msal-image" /> Send verified login link
                    </Button>
                    <MicrosoftLoginButton handleLogin={handleLogin} />
                    <div style={{ width: "100%", wordWrap: "break-word", placeSelf: "center", marginTop: "" }}>
                      {error && <p style={{ color: "red", width: "250px" }}>{error}</p>}
                    </div>
                  </div>
                  :
                  <div style={{textAlign: "center", display:"flex", justifyContent: "center", flexDirection:"column"}}>
                    <div style={{ width: "300px", wordWrap: "break-word", placeSelf: "center", marginTop: "" }}>
                      <p style={{ color: "green", width: "300px" }}>{success}</p>
                    </div>
                    <Button style={{ margin: "0 auto" }} className="button microsoft microsoftButton"  onClick={backfunc}>
                      <Image src={arrowleftline} className="msal-image" />Back
                    </Button>
                  </div>
                }
              </FluentCard>
            </Col>
          </Row>
          :
          <Row>
            <Col
              style={{
                marginTop: "18vh",
              }}
            >
              <FluentCard className="fluent-card">
                <div className="containerLogo">
                  <img
                    src={cclogo}
                    alt="care-coach logo"
                    style={{ width: "250px" }}
                  />
                  <div className="text-overlay">
                    <h4>Portal</h4>
                  </div>
                </div>
                <MicrosoftLoginButton handleLogin={handleLogin} />
                <Button className="button microsoft microsoftButton" onClick={handleEmailLogin}>
                  <Image src={mailline} className="msal-image" /> Sign in with Email
                </Button>
                <div style={{ width: "300px", wordWrap: "break-word", placeSelf: "center", marginTop: "" }}>
                  {error && <p style={{ color: "red", width: "300px" }}>{error}</p>}
                </div>
              </FluentCard>
            </Col>
          </Row>
      )}
    </div>
  );
}

export default LoginScreen;