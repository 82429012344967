import { React, useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerlogData, fetchDeviceIdData } from "../actions/logActions";
import "../css/log.css"
import TablePagination from '@mui/material/TablePagination';
import noData from "../images/no-data.svg";
import { Image } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

function CustomerLog() {

  const [deviceIdFilter, setDeviceIdFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [content, setContent] = useState("");
  const [startDate, setStartDate] = useState("");
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const log = useSelector((state) => state.log);
  const deviceList = log.deviceIdData ? log.deviceIdData : []
  const deviceListUser = userInfo ? userInfo.devices : [];
  const allDevice = deviceListUser.length ? Array.isArray(deviceListUser) ? deviceListUser : [] : Array.isArray(deviceList) ? deviceList : []
  const customerName = userInfo?.allorganization?.[0]?.customer_name || ""
  const customerLogData = log.customerLogData ? log.customerLogData : []
  const customerLogLoader = log.customerLogLoading
  const customerData = customerLogData.logs ? customerLogData.logs : []
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const totalRecords = customerLogData?.total_records || 0;
  const totalPages = Math.ceil(totalRecords / rowsPerPage);

  useEffect(() => {
    // if (customerName.length) {
    dispatch(fetchDeviceIdData(customerName || ''));
    // }
  }, [dispatch]);

  useEffect(() => {
    // if (customerName.length) {
    const data = {
      customer_name: customerName || '',
      devices: deviceListUser.length ? deviceListUser : [],
      page: page == 0 ? 1 : page + 1,
      page_size: rowsPerPage,
      filter_date: startDate ? startDate : '',
      device_id: deviceIdFilter ? deviceIdFilter : '',
      content: content ? content : '',
      type: typeFilter ? typeFilter : '',
    }
    dispatch(fetchCustomerlogData(data))
    // }

  }, [dispatch, page, rowsPerPage, deviceIdFilter, typeFilter, content, startDate])


  const handleDeviceFilterChange = (e) => {
    setDeviceIdFilter(e.target.value);
  };

  const handleTypeFilterChange = (e) => {
    setTypeFilter(e.target.value);
  };

  const handleDateChange = (e) => {
    const selectedDate = e.target.value;
    const timestamp = Math.floor(new Date(selectedDate).getTime() / 1000);
    setStartDate(timestamp);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  return (
    <div className="App sidebar-screen">
      <SideBar />
      <main style={{ width: "100vw" }} className="supportMain">
        <div className="supportContainer">

          {/* Filters Section */}
          <div className="filters mt-4">
            <h5>Filters:</h5>
            {/* Date picker */}
            <input
              id="startDate"
              type="date"
              className="form-control"
              value={startDate ? new Date(startDate * 1000).toISOString().split("T")[0] : ""}
              onChange={handleDateChange}
            />
            {/* Device ID Dropdown */}
            <select
              value={deviceIdFilter}
              onChange={handleDeviceFilterChange}
            >
              <option value="">Device ID</option>
              {allDevice.map((device, index) => (
                <option key={index} value={device}>
                  {device}
                </option>
              ))}
            </select>
            {/* File Type Dropdown */}
            <select
              value={typeFilter}
              onChange={handleTypeFilterChange}
            >
              <option value="">Type</option>
              <option value="0">Observation</option>
              <option value="1">Interaction</option>
            </select>

            {/* Name & Description Search Box */}
            <input
              type="text"
              placeholder="Search by Content"
              value={content}
              onChange={handleContentChange}
            />
          </div>

          {/* Light Grey Divider Line */}
          <hr className="divider" />


          {/* Table Section */}

          {customerLogLoader == true ?
            (
              <Spinner className="custom-spinner" animation="border" style={{ width: "3rem", height: "3rem" }} />
            )
            : (
              <div className="table-container">
                <table>
                  {/* Table Headers */}
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Device ID</th>
                      <th>Type</th>
                      <th>Content</th>
                    </tr>
                  </thead>

                  {/* Table Body */}
                  <tbody>
                    {customerLogData.total_records === 0 ? (
                      <tr>
                        <td colSpan="4" className="no-records">
                          <div className='image-nodata'>
                            <Image height="100" src={noData} alt="noData" />
                          </div>
                          <h5 className="p-3"> No record Found....</h5>

                        </td>
                      </tr>
                    ) : (
                      customerData.map((item) => (
                        <tr key={item.record_id} >
                          <td>{new Date(item.adatetime * 1000).toLocaleString("en-US", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true
                          })}</td>
                          <td>{item.customer_name}</td>
                          <td>{item.interaction == 0 ? 'Observation' : 'Interaction'}</td>
                          <td>{item.content}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            )}


          {/* Pagination Section */}
          <div className="pagination">
            <TablePagination
              component="div"
              count={totalRecords}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Items per page"
            />
          </div>
        </div>
      </main>
    </div>
  );
}

export default CustomerLog;