import axios from 'axios';

export const FETCH_ALL_MEDIA_DATA_REQUEST = 'FETCH_ALL_MEDIA_DATA_REQUEST';
export const FETCH_ALL_MEDIA_DATA_SUCCESS = 'FETCH_ALL_MEDIA_DATA_SUCCESS';
export const FETCH_ALL_MEDIA_DATA_FAILURE = 'FETCH_ALL_MEDIA_DATA_FAILURE';
export const UPLOAD_MEDIA_DATA_REQUEST = 'UPLOAD_MEDIA_DATA_REQUEST';
export const UPLOAD_MEDIA_DATA_SUCCESS = 'UPLOAD_MEDIA_DATA_SUCCESS';
export const UPLOAD_MEDIA_DATA_FAILURE = 'UPLOAD_MEDIA_DATA_FAILURE';



const django_base_url = process.env.REACT_APP_DJANGO_BASE_URL;
const api_key = process.env.REACT_APP_CAREDOTCOACHAUTH

export const fetchAllMedaiData = (data) => async (dispatch) => {
    dispatch({ type: FETCH_ALL_MEDIA_DATA_REQUEST });

    try {
        const response = await axios.post(
            `${django_base_url}/media/all_portal_media/`, 
            data, 
            {
                headers: { 'caredotcoachauth': api_key }
            }
        );

        dispatch({
            type: FETCH_ALL_MEDIA_DATA_SUCCESS,
            payload: response.data || [],
        });

    } catch (error) {
        dispatch({
            type: FETCH_ALL_MEDIA_DATA_FAILURE,
            payload: error.message || 'Something went wrong',
        });
        console.error('Error posting Outcome data:', error);
    }
};

export const uploadMedaiData = (data) => async (dispatch) => {
    dispatch({ type: UPLOAD_MEDIA_DATA_REQUEST });

    try {
        const response = await axios.post(
            `${django_base_url}/media/portal_client_media/`, 
            data, 
            {
                headers: { 'caredotcoachauth': api_key }
            }
        );

        dispatch({
            type: UPLOAD_MEDIA_DATA_SUCCESS,
            payload: response.data || [],
        });

    } catch (error) {
        dispatch({
            type: UPLOAD_MEDIA_DATA_FAILURE,
            payload: error.message || 'Something went wrong',
        });
        console.error('Error posting Outcome data:', error);
    }
};



