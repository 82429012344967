/**
 * Reducer file for Update API Endpoint
 */
import {
  FETCH_ALL_MEDIA_DATA_REQUEST, FETCH_ALL_MEDIA_DATA_SUCCESS, FETCH_ALL_MEDIA_DATA_FAILURE,
  UPLOAD_MEDIA_DATA_REQUEST, UPLOAD_MEDIA_DATA_SUCCESS, UPLOAD_MEDIA_DATA_FAILURE,
} from '../actions/mediaActions';

const initialState = {
  loading: false,
  allMediaData: {},
  error: null,
  uploadLoading: false,
  uploadMediaData: {},
  uploadError: null,
};

export const mediaReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_MEDIA_DATA_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        allMediaData: {},
      };
    case FETCH_ALL_MEDIA_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        allMediaData: action.payload,
        error: null,
      };
    case FETCH_ALL_MEDIA_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        allMediaData: {},
      };
    case UPLOAD_MEDIA_DATA_REQUEST:
      return {
        ...state,
        uploadLoading: true,
        uploadError: null,
        uploadMedaiData: {},
      };
    case UPLOAD_MEDIA_DATA_SUCCESS:
      return {
        ...state,
        uploadLoading: false,
        uploadMedaiData: action.payload,
        uploadError: null,
      };
    case UPLOAD_MEDIA_DATA_FAILURE:
      return {
        ...state,
        uploadLoading: false,
        uploadError: action.payload,
        uploadMedaiData: {},
      };
    default:
      return state;
  }
};
