// This module contains axios interceptors. General axios settings and specific http status
// codes can de dealt with through interceptors

import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

// get authorization token from user object on page load/refresh
// set authorization token in axios headers
const user = JSON.parse(localStorage.getItem("user"));
if (user) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.token.access}`;
}
  